import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import ITemplate from './models/ITemplate'

export interface ITemplateStoreState extends Partial<ODataState<ITemplate>> {
}

store.registerModule('Templates', ODataStore.extend<ITemplateStoreState>({
  namespaced: true,
  state: {
  },
  getters: {
    results(state) {
      return state.result.value
    },
    getById: state => (id: number) => {
      return state.result.value.find(x => x.id === id)
    }
  },
  actions: {
    async getTemplateById({ dispatch }, id) {
      const request: ODataRequest = {
        url: `GraphTemplates(${id})`,
        params: {
          $expand: 'graphQueries($select=id,name;$orderby=name;$filter=isTemporary eq false)'
        }
      }
      const template = await dispatch('get', request)
      return template
    },
    async load({ commit, dispatch }) {
      const request: ODataRequest = {
        url: 'GraphTemplates',
        params: {
          $orderby: 'userId, name'
        }
      }
      const result = await dispatch('get', request)
      commit('setResult', result)
    },
    async saveTemplate({ dispatch }, template: ITemplate) {
      let result = template
      if (template.id) {
        const request: ODataRequest = {
          url: `GraphTemplates(${template.id})`,
          data: template,
          flatten: true
        }
        await dispatch('put', request)
      } else {
        const formData = new FormData()
        formData.append('templateFile', template.$_file)
        formData.append('name', template.name)

        const request: ODataRequest = {
          url: 'GraphTemplates',
          data: formData
        }
        result = await dispatch('post', request)
      }
      await dispatch('load')

      return result
    },
    async deleteTemplate({ dispatch }, template: ITemplate) {
      const request: ODataRequest = {
        url: `GraphTemplates(${template.id})`
      }
      await dispatch('delete', request)
      await dispatch('load')
    },
    async reassignTemplate({ dispatch }, payload: any) {
      const template: ITemplate = payload.template
      const replacementTemplateId: number = payload.replacementTemplateId

      const request: ODataRequest = {
        url: `GraphTemplates(${template.id})/Reassign`,
        data: {
          templateId: replacementTemplateId
        }
      }
      await dispatch('post', request)
      await dispatch('deleteTemplate', template)
    },
    async downloadTemplate({ dispatch }, template: ITemplate) {
      const request: ODataRequest = {
        url: `GraphTemplates(${template.id})/Download`,
        responseType: 'blob',
        noLoading: true
      }
      const response = await dispatch('get', request)
      const blob = new Blob([response], {
        type: response.type
      })
      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = downloadUrl
      link.setAttribute('download', `${template.name}.docx`)

      document.body.appendChild(link)

      link.click()
      link.remove()
    }
  }
}))
