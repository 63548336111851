















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import IListingFamily from '../models/IListingFamily'
const { mapGetters } = createNamespacedHelpers('ListingHierarchies')

export default Vue.extend({
  props: {
    value: [String, Number],
    disabled: Boolean
  },
  computed: {
    ...mapGetters(['families']),
    tooltip(): string {
      const families = this.families as IListingFamily[]
      const selectedFamily = families.find((family) => family.id === this.value)
      return selectedFamily?.name
    }
  }
})
