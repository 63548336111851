import ODataMixin from '@/mixins/ODataMixin'
import IODataQuery from '@/models/IODataQuery'
import { ODataRequest } from '@/store/ODataStore'
import IListingHierarchy from '../models/IListingHierarchy'

export default ODataMixin.extend({
  computed: {
    products(): IListingHierarchy[] {
      return this.$store.getters['ListingHierarchies/products']
    }
  },
  methods: {
    async refresh() {
      const table = this.$refs.table as any
      if (table) {
        await table.getItems()
      }
      await this.$store.dispatch('ListingHierarchies/getListingHierarchies')
    },
    async getListingHierarcyById(id: number, query: IODataQuery) {
      return this.getSingle<IListingHierarchy>(`ListingHierarchies(${id})`, query)
    },
    async getChildren(id: number) {
      const query = {
        $expand: 'inverseParent($orderby=name)',
        $select: 'inverseParent,nodeTypeId'
      }
      const listingHierarchy = await this.getListingHierarcyById(id, query)

      return listingHierarchy.inverseParent
    },
    async saveListingHierarchy(listingHierarchy: IListingHierarchy) {
      let result = listingHierarchy
      if (listingHierarchy.id) {
        await this.put<IListingHierarchy>(`ListingHierarchies(${listingHierarchy.id})`, listingHierarchy)
      } else {
        result = (await this.post<IListingHierarchy>('ListingHierarchies', listingHierarchy)).data
      }
      await this.refresh()
      return result
    },
    async deleteListingHierarchy(listingHierarchy: IListingHierarchy) {
      let nodeTypeName = this.getNodeTypeName(listingHierarchy.nodeTypeId)
      let message = `Are you sure you want to delete the ${nodeTypeName} '${listingHierarchy.name}'?`
      const options = { title: 'Delete?' }

      // do not delete if there are child items
      const children = await this.getChildren(listingHierarchy.id)
      const childNames = children.map((x) => `<li>${x.name}</li>`).join('')

      if (children.length) {
        nodeTypeName = this.getNodeTypeName(children[0].nodeTypeId, true)
        message = `Cannot delete '${listingHierarchy.name}'. It is being used by the following ${nodeTypeName}:<ul>${childNames}</ul>`
        await this.$confirm(message, {
          title: 'Cannot Delete',
          buttonFalseText: null,
          buttonTrueText: 'Ok'
        })
        return
      }

      if (await this.$confirm(message, options)) {
        await this.delete(`ListingHierarchies(${listingHierarchy.id})`)
        await this.refresh()
      }
    },
    async exportToFile() {
      const request: ODataRequest = {
        url: 'Reports/ProductHierarchy?export=true',
        responseType: 'blob'
      }

      const response = (await this.request<any>(request)).data
      const blob = new Blob([response], {
        type: response.type
      })
      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = downloadUrl

      link.setAttribute('download', 'ProductHierarchy.xlsx')

      document.body.appendChild(link)

      link.click()
      link.remove()
    },
    getNodeTypeName(id: number, plural?: boolean) {
      switch (id) {
        case 1:
          return plural ? 'families' : 'family'
        case 2:
          return plural ? 'groups' : 'group'
        case 3:
          return plural ? 'products' : 'product'
        default:
          return ''
      }
    }
  }
})
