import Axios from 'axios'
import moment from 'moment'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', { ...required, message: 'This field is required.' })
extend('date', {
  validate(value: string) {
    return moment(value, 'MM/DD/YYYY', true).isValid()
  },
  message: 'This is not a valid date.'
})
extend('url', {
  validate(value: string) {
    const regex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    return !value || regex.test(value)
  },
  message: 'This is not a valid URL.'
})
extend('listing_name_unique', {
  async validate(value, params) {
    let $filter = `displayName eq '${value}'`
    const id = params[0]
    if (id) {
      $filter += ` and id ne ${id}`
    }
    const response = await Axios.get<number>('Listings/$count', { params: { $filter } })
    return response.data === 0
  },
  message: 'This listing name already exists.'
})
extend('family_name_unique', {
  async validate(value, params) {
    const id: number | string = params[0]
    let $filter = `nodeTypeId eq 1 and name eq '${value}'`
    if (id && id !== 'undefined') {
      $filter += ` and id ne ${id}`
    }
    const response = await Axios.get<number>('ListingHierarchies/$count', { params: { $filter } })
    return response.data === 0
  },
  message: 'This family name already exists.'
})
extend('group_name_unique', {
  async validate(value, params) {
    const familyId: number | string = params[0]
    const id: number | string = params[1]
    if (familyId === 'undefined') {
      return true
    }
    let $filter = `nodeTypeId eq 2 and parentId eq ${familyId} and name eq '${value}'`
    if (id && id !== 'undefined') {
      $filter += ` and id ne ${id}`
    }
    const response = await Axios.get<number>('ListingHierarchies/$count', { params: { $filter } })
    return response.data === 0
  },
  message: 'This group name already exists.'
})
extend('product_name_unique', {
  async validate(value, params) {
    const groupId: number | string = params[0]
    const id: number | string = params[1]
    if (groupId === 'undefined') {
      return true
    }
    let $filter = `nodeTypeId eq 3 and parentId eq ${groupId} and name eq '${value}'`

    if (id && id !== 'undefined') {
      $filter += ` and id ne ${id}`
    }
    const response = await Axios.get<number>('ListingHierarchies/$count', { params: { $filter } })
    return response.data === 0
  },
  message: 'This product name already exists.'
})
extend('edition_name_unique', {
  async validate(value) {
    const $filter = `displayName eq '${value}'`
    const response = await Axios.get<number>('Editions/$count', { params: { $filter } })
    return response.data === 0
  },
  message: 'This edition name already exists.'
})
extend('template_name_unique', {
  async validate(value, params) {
    let $filter = `name eq '${encodeURI(value).replace(/'/g, '%27')}'`
    const id = params[0]
    if (!isNaN(id)) {
      $filter += ` and id ne ${id}`
    }
    const response = await Axios.get<number>('GraphTemplates/$count', { params: { $filter } })
    return response.data === 0
  },
  message: 'This template name already exists.'
})
