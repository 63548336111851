import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":_vm.maxWidth,"persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"pb-1"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.question))])]),_c(VCardText,{staticClass:"pt-1"},[_vm._v(_vm._s(_vm.description))]),_c(VCardActions,[(_vm.showCancelButton)?_c(VBtn,{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.cancelButtonText))]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(_vm._s(_vm.confirmButtonText))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }