import store from '@/store'
import Axios from 'axios'
import IRelease from '../models/IRelease'

export interface IAlertTypesStoreState {
  loading: boolean,
  error: Error,
  releases: IRelease[]
}

store.registerModule<IAlertTypesStoreState>('Releases', {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    releases: []
  },
  getters: {
    releases(state) {
      return state.releases || []
    },
    loading(state) {
      return state.loading
    },
    error(state) {
      return state.error
    }
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading
    },
    setReleases(state, releases: IRelease[]) {
      state.releases = releases
    },
    setError(state, err) {
      state.error = err
    }
  },
  actions: {
    async getReleasesByProductId({ commit }, productId: number) {
      try {
        commit('setLoading', true)
        const response = await Axios.get(`Releases/ForProduct(productId=${productId})`)
        commit('setReleases', response.data.value)
      } catch (err) {
        commit('setError', err)
      } finally {
        commit('setLoading', false)
      }
    },
    async saveRelease({ state, commit }, release: IRelease) {
      if (release.id) {
        await Axios.put(`Releases(${release.id})`, release)
      } else {
        const result = await Axios.post<IRelease>('Releases', release)
        commit('setReleases', [...state.releases, result.data])
        return result.data
      }
    }
  }
})
