import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"nudge-bottom":"65"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({class:{ 'v-btn--active': _vm.$route.path.startsWith('/Manage') },attrs:{"disabled":_vm.disabled,"text":""}},on),[_vm._v(" Tools "),_c(VIcon,{attrs:{"right":""}},[_vm._v("$dropdown")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.$emit('create-listing')}}},[_c(VListItemIcon,{staticClass:"mr-1"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$add")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Create Listing")])],1)],1),_c(VListItem,{attrs:{"to":{ name: 'Listings', query: { filter: 'Ready For Live' } },"exact":""}},[_c(VListItemIcon,{staticClass:"mr-1"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$publish")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Publish to Live")])],1)],1),_c(VDivider),_c(VListItem,{attrs:{"to":"/Manage/Editions"}},[_c(VListItemIcon,{staticClass:"mr-1"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$manage")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Manage")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }