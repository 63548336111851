import ViewModel from '@/models/ViewModel'
import IListingContact from './IListingContact'
import ListingContactRoleModel from './ListingContactRoleModel'

export default class ListingContactModel extends ViewModel implements IListingContact {
  $_sourceDataName: string
  id: number
  uniqueId: string
  modifiedDate: string
  modifiedBy: string
  listingId: number
  name: string
  listingContactRoles = [new ListingContactRoleModel()]

  constructor(contact?: IListingContact) {
    super()
    if (contact) {
      Object.assign(this, contact)
      this.listingContactRoles = contact.listingContactRoles.map(x => new ListingContactRoleModel(x))
    }
  }
}
