import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import store from '@/store'
import IListingHierarchy from './models/IListingHierarchy'
import IListingFamily from './models/IListingFamily'

export interface ListingHierarchiesStoreState extends Partial<ODataState<IListingHierarchy>> {
}

store.registerModule('ListingHierarchies', ODataStore.extend<ListingHierarchiesStoreState>({
  state: {
  },
  getters: {
    getById: state => (id: number) => {
      return state.result.value.find(x => x.id === id)
    },
    getByNodeTypeId: state => (nodeTypeId: number, parentId: number) => {
      return state.result.value.filter(x => x.nodeTypeId === nodeTypeId && (!parentId || x.parentId === parentId))
    },
    families(state) {
      const nodes = state.result.value

      const families: IListingFamily[] = nodes.filter(x => x.nodeTypeId === 1)

      families.forEach(family => {
        family.$_groups = nodes.filter(x => x.parentId === family.id)

        family.$_groups.forEach(group => {
          group.$_products = nodes.filter(x => x.parentId === group.id)
        })
      })

      return families
    },
    groups(state) {
      return state.result.value
        .filter(x => x.nodeTypeId === 2)
        .map(group => {
          return {
            ...group,
            $_familyName: state.result.value.find(x => x.id === group.parentId)?.name
          }
        })
    },
    products(state) {
      const products = state.result.value.filter(x => x.nodeTypeId === 3)

      return products.map(product => {
        const group = state.result.value.find(x => x.id === product.parentId)
        const family = state.result.value.find(x => x.id === group?.parentId)

        return {
          ...product,
          $_groupName: group?.name,
          $_familyId: family?.id,
          $_familyName: family?.name
        }
      })
    },
    getChildren: state => (parentId: number) => {
      return state.result.value.filter(x => x.parentId === parentId)
    },
    getDescendants: (_state, getters) => (node: IListingHierarchy) => {
      const nodes = []

      if (!node) {
        return nodes
      }

      nodes.push(node)

      const children = getters.getChildren(node.id)

      children.forEach(child => {
        nodes.push(...getters.getDescendants(child))
      })

      return nodes
    },
    getAncestors: (_state, getters) => (node: IListingHierarchy) => {
      const ancestors = []
      const parent = getters.getById(node.parentId)
      if (parent) {
        ancestors.push(parent)
        ancestors.push(...getters.getAncestors(parent))
      }
      return ancestors
    },
    getDescendantsByParentId: (_state, getters) => (parentId: number) => {
      const root = getters.getById(parentId)
      return getters.getDescendants(root)
    }
  },
  mutations: {
  },
  actions: {
    async getListingHierarchies({ commit, dispatch }) {
      const request: ODataRequest = {
        url: 'ListingHierarchies',
        params: {
          $orderby: 'sortName, name'
        }
      }
      const result = await dispatch('get', request)
      commit('setResult', result)
    }
  }
}))
