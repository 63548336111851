import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.alert)?_c('div',[_c(VSnackbar,{staticClass:"pa-0",attrs:{"value":!!_vm.alert && _vm.alert.messages.length > 0,"bottom":"","color":_vm.color,"timeout":_vm.alert.timeout},on:{"input":_vm.hideAlert},scopedSlots:_vm._u([(_vm.alert.timeout === -1)?{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":"","outlined":""},on:{"click":function($event){return _vm.$store.dispatch('hideAlert')}}},'v-btn',attrs,false),[_vm._v("Close")])]}}:null],null,true)},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"shrink"},[_c(VIcon,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.icon))])],1),_c(VCol,[(_vm.alert.messages.length > 1)?_c('ul',_vm._l((_vm.alert.messages),function(message){return _c('li',{key:message},[_vm._v(" "+_vm._s(message)+" ")])}),0):_c('div',[_vm._v(_vm._s(_vm.alert.messages[0]))])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }