import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"width":_vm.width,"scrollable":_vm.scrollable},on:{"click:outside":function($event){return _vm.$emit('cancel')}}},[(_vm.value)?_c('Form',{on:{"submit":function($event){return _vm.$emit('submit')},"reset":function($event){return _vm.$emit('cancel')}}},[_c(VCard,{attrs:{"height":_vm.height}},[_c(VCardTitle,{staticClass:"headline grey lighten-4",attrs:{"primary-title":""}},[(_vm.icon)?_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c(VDivider),_c(VCardText,{staticClass:"pa-0"},[_vm._t("default")],2),_c(VDivider),_c(VCardActions,[_vm._t("actions",[_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"secondary","type":"reset"}},[_vm._v(_vm._s(_vm.cancelText))]),_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading || _vm.disabled,"color":_vm.submitColor,"type":"submit"}},[_vm._v(_vm._s(_vm.submitText))])])],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }