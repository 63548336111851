<template>
  <v-footer app absolute>
    <v-col class="text-center body-2">&copy;{{ year }} Copyright Microsoft | <a href="http://go.microsoft.com/fwlink/?LinkId=518021" target="blank">Data Privacy Notice</a></v-col>
  </v-footer>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>
