import ViewModel from '@/models/ViewModel'
import IEnvironment from './IEnvironment'
import IListingPublishStatus from './IListingPublishStatuses'
import { PublishStatusEnum } from './PublishStatusEnum'

export default class ListingPublishStatusModel extends ViewModel implements IListingPublishStatus {
  $_status: PublishStatusEnum
  $_publishOnOverridden: boolean = false
  id: number
  uniqueId: string = null
  modifiedDate: string = null
  modifiedBy: string = null
  listingId: number
  environmentId: number
  listingHash: string = null
  autoPublish: boolean = false
  publishToEnvironmentId: number
  publishOn: string = null
  publishOnOverride: string = null
  approvers: string = null
  approved: boolean = false
  publishedBy: string = null
  publishedDate: string = null
  environment: IEnvironment

  constructor(publishStatus: IListingPublishStatus, hash: string) {
    super()
    Object.assign(this, publishStatus)

    if (!this.publishedDate) {
      this.$_status = PublishStatusEnum.Unpublished
    } else if (hash === this.listingHash) {
      this.$_status = PublishStatusEnum.Current
    } else {
      this.$_status = PublishStatusEnum.UpdateNeeded
    }

    this.$_publishOnOverridden = !!publishStatus.publishOnOverride
  }
}
