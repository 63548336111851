import router from '@/router'

router.addRoutes([
  {
    path: '/Listings/:id',
    name: 'Listing',
    meta: {
      title: 'Listing'
    },
    components: {
      default: () => import(/* webpackChunkName: "Listing" */ './views/Listing.vue')
    }
  },
  {
    path: '/Listings',
    name: 'Listings',
    meta: {
      title: 'Listings'
    },
    components: {
      default: () => import(/* webpackChunkName: "Listings" */ './views/Listings.vue')
    }
  }
])
