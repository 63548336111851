import router from '@/router'

router.addRoutes([
  {
    path: '/Imports/:id',
    name: 'Import',
    meta: {
      title: 'Import'
    },
    components: {
      default: () => import(/* webpackChunkName: "Import" */ './views/Import.vue')
    }
  },
  {
    path: '/Imports',
    name: 'Imports',
    meta: {
      title: 'Imports'
    },
    components: {
      default: () => import(/* webpackChunkName: "imports" */ './views/Imports.vue')
    }
  }
])
