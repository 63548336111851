













































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import IEdition from '../models/IEdition'
const { mapGetters, mapActions } = createNamespacedHelpers('Editions')

export default Vue.extend({
  props: {
    value: {
      type: [Number],
      default: 0
    },
    blankItem: Boolean,
    blankItemText: {
      type: String,
      default: 'N/A'
    },
    filter: {
      type: Function,
      default() {
        return true
      }
    },
    disabled: Boolean
  },
  data() {
    return {
      newEditionName: null,
      showCreateEdition: false
    }
  },
  computed: {
    ...mapGetters(['editions', 'loading', 'error']),
    items(): IEdition[] {
      const items = this.editions.filter(this.filter) as IEdition[]
      if (this.blankItem) {
        items.unshift({ id: 0, displayName: this.blankItemText })
      }
      return items
    },
    tooltip(): string {
      const editions = this.editions as IEdition[]
      const selectedEdition = editions.find(
        (edition) => edition.id === this.value
      )
      return selectedEdition?.displayName
    }
  },
  methods: {
    ...mapActions({
      saveEdition: 'saveEdition'
    }),
    onCancel() {
      this.showCreateEdition = false
      this.newEditionName = null
    },
    async onSubmit() {
      const edition: IEdition = {
        displayName: this.newEditionName
      }
      const created: IEdition = await this.saveEdition(edition)
      this.$emit('input', created.id)
      this.showCreateEdition = false
      this.newEditionName = null
    }
  }
})
