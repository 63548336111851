import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import store from '@/store'
import IAzureRetirement from './models/IAzureRetirement'
import Filter from '@/models/Filter'

export interface StoreState extends Partial<ODataState<IAzureRetirement>> {
  filters: Filter[]
}

store.registerModule('AzureRetirements', ODataStore.extend<StoreState>({
  state: {
    filters: [
      {
        name: 'All',
        count: 0,
        filter: ''
      },
      {
        name: 'Updated Since Preview',
        count: 0,
        filter: 'previewCurrent eq false and previewPublishedDate ne null'
      },
      {
        name: 'Ready For Live',
        count: 0,
        filter: 'previewCurrent eq true and liveCurrent eq false'
      },
      {
        name: 'Live',
        count: 0,
        filter: 'livePublishedDate ne null'
      }
    ]
  },
  getters: {
    azureRetirements(state) {
      return state.result.value
    },
    azureRetirementsCount(state) {
      return state.result.count
    },
    getAzureRetirementById: state => id => {
      return state.result.value.find(x => x.id === id)
    },
    azureRetirementFilters(state) {
      return state.filters
    },
    getAzureRetirementFilterQuery: state => (name: string) => {
      return state.filters.find(x => x.name === name)?.filter
    }
  },
  mutations: {
    setFilters(state, filters: Filter[]) {
      state.filters = filters
    }
  },
  actions: {
    async getFilterCounts({ state, commit, dispatch }) {
      const namedFilters = [...state.filters]
      const promises = namedFilters.map(namedFilter => {
        const request: ODataRequest = {
          url: 'azureRetirements/flat',
          params: {
            $count: true,
            $top: 0
          }
        }
        const filters: string[] = []
        if (namedFilter.filter) {
          filters.push(namedFilter.filter)
        }
        request.params.$filter = filters.join(' and ') || undefined
        return dispatch('get', request)
      })

      const results = await Promise.all(promises)
      for (let i = 0; i < results.length; i++) {
        namedFilters[i].count = results[i]['@odata.count']
      }
      commit('setFilters', namedFilters)
    },
    async getAzureRetirements({ commit, dispatch }, params) {
      const request: ODataRequest = {
        url: 'azureRetirements/flat',
        params
      }
      const result = await dispatch('get', request)
      commit('setResult', result)
    },
    async publish({ dispatch }, params) {
      const request: ODataRequest = {
        url: `AzureRetirements(${params.id})/Publish`,
        data: {
          environmentId: params.environmentId
        },
        concurrency: params.concurrency
      }
      try {
        await dispatch('post', request)
      } catch (err) {
        await dispatch('showErrorAlert', err, { root: true })
      }
    },
    async promote({ dispatch }, params) {
      const request: ODataRequest = {
        url: `PublishedAzureRetirements(${params.id})/Promote`,
        data: {
          environmentId: params.environmentId
        },
        concurrency: params.concurrency
      }
      try {
        await dispatch('post', request)
      } catch (err) {
        await dispatch('showErrorAlert', err, { root: true })
      }
    },
    async unpublish({ dispatch }, id) {
      const request: ODataRequest = {
        url: `PublishedAzureRetirements(${id})`
      }
      try {
        await dispatch('delete', request)
      } catch (err) {
        await dispatch('showErrorAlert', err, { root: true })
      }
    },
    async archive({ dispatch }, params) {
      const request: ODataRequest = {
        url: `AzureRetirements(${params.id})/Archive`,
        data: {
          note: params.note
        }
      }
      try {
        await dispatch('post', request)
      } catch (err) {
        await dispatch('showErrorAlert', err, { root: true })
      }
    },
    async restore({ dispatch }, params) {
      const request: ODataRequest = {
        url: `AzureRetirements(${params.id})/Restore`
      }
      try {
        await dispatch('post', request)
      } catch (err) {
        await dispatch('showErrorAlert', err, { root: true })
      }
    }
  }
}))
