












































import ListingHierarchiesMixin from '../mixins/ListingHierarchiesMixin'
import IListingHierarchy from '../models/IListingHierarchy'

export default ListingHierarchiesMixin.extend({
  props: {
    value: [String, Number],
    disabled: Boolean,
    groupId: {
      type: [Number, undefined]
    }
  },
  data() {
    return {
      newProductName: null as string,
      showOverlay: false
    }
  },
  computed: {
    items(): IListingHierarchy[] {
      return this.products.filter((x) => x.parentId === this.groupId)
    },
    tooltip(): string {
      const products = this.products as IListingHierarchy[]
      const selectedProduct = products.find(
        (product) => product.id === this.value
      )
      return selectedProduct?.name
    }
  },
  methods: {
    onCancel() {
      this.newProductName = null
      this.showOverlay = false
    },
    async onSubmit() {
      const newProduct: IListingHierarchy = {
        name: this.newProductName,
        nodeTypeId: 3,
        parentId: this.groupId
      }

      const created = await this.saveListingHierarchy(newProduct)
      this.$emit('input', created.id)
      this.showOverlay = false
      this.newProductName = null
    }
  }
})
