import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,_vm._g(_vm._b({attrs:{"value":_vm.value,"close-on-content-click":false},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,{"on":on})]}}],null,true)},'v-menu',_vm.$attrs,false),_vm.$listeners),[(_vm.value)?_c('Form',{on:{"submit":function($event){return _vm.$emit('submit')},"reset":function($event){return _vm.$emit('cancel')}}},[_c(VCard,{attrs:{"width":_vm.width}},[(_vm.title)?_c(VCardTitle,{domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_c(VCardText,[_vm._t("default")],2),_c(VCardActions,[_c(VBtn,{attrs:{"type":"reset","disabled":_vm.loading,"color":_vm.cancelColor}},[_vm._v(_vm._s(_vm.cancelText))]),_c(VSpacer),_c(VBtn,{attrs:{"type":"submit","disabled":_vm.loading,"color":_vm.submitColor}},[_vm._v(_vm._s(_vm.submitText))])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }