




























import FormMixin from '@/mixins/FormMixin'
import SupportPolicySelect from '@/areas/supportPolicies/components/SupportPolicySelect.vue'
import FamilySelect from '@/areas/listingHierarchies/components/FamilySelect.vue'
import GroupSelect from '@/areas/listingHierarchies/components/GroupSelect.vue'
import ProductSelect from '@/areas/listingHierarchies/components/ProductSelect.vue'
import EditionSelect from '@/areas/editions/components/EditionSelect.vue'

export default FormMixin.extend({
  components: {
    SupportPolicySelect,
    FamilySelect,
    GroupSelect,
    ProductSelect,
    EditionSelect
  }
})
