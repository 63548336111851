import store from '@/store'
import Axios from 'axios'
import IAttributeOverrideType from './models/IAttributeOverrideType'

export interface IOverrideTypeStoreState {
  loading: boolean,
  error: Error,
  overrideTypes: IAttributeOverrideType[]
}

store.registerModule<IOverrideTypeStoreState>('OverrideTypes', {
  namespaced: true,
  getters: {
    overrideTypes(state) {
      return state.overrideTypes
    }
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading
    },
    setAlertTypes(state, overrideTypes: IAttributeOverrideType[]) {
      state.overrideTypes = overrideTypes
    }
  },
  actions: {
    async getOverrideTypes({ commit }) {
      try {
        commit('setLoading', true)
        const response = await Axios.get('AttributeOverrideTypes', { params: { $orderby: 'name' } })
        commit('setAlertTypes', response.data.value)
      } finally {
        commit('setLoading', false)
      }
    }
  }
})
