import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"nudge-bottom":"65"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({class:{ 'v-btn--active': _vm.$route.path.startsWith('/Reports') },attrs:{"disabled":_vm.disabled,"text":""}},on),[_vm._v(" Reports "),_c(VIcon,{attrs:{"right":""}},[_vm._v("$dropdown")])],1)]}}])},[_c(VList,_vm._l((_vm.reports),function(report){return _c(VListItem,{key:report.id,attrs:{"to":{ name: 'Report', params: { id: report.id } }}},[_c(VListItemTitle,[_vm._v(_vm._s(report.name))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }