














import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import ISupportPolicy from '@/areas/listings/models/ListingModel'
const { mapGetters } = createNamespacedHelpers('SupportPolicies')

export default Vue.extend({
  props: {
    value: [String, Number]
  },
  computed: {
    ...mapGetters(['assignableSupportPolicies']),
    tooltip(): string {
      const policies = this.assignableSupportPolicies as ISupportPolicy[]
      const selectedPolicy = policies.find((policy) => policy.id === this.value)
      return selectedPolicy?.displayName
    }
  }
})
