import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import store from '@/store'
import IEdition from './models/IEdition'

export interface StoreState extends Partial<ODataState<IEdition>> {
}

store.registerModule('Editions', ODataStore.extend<StoreState>({
  state: {
  },
  getters: {
    editions(state) {
      return state.result.value
    },
    editionsCount(state) {
      return state.result.count
    },
    getEditionById: state => id => {
      return state.result.value.find(x => x.id === id)
    },
    getEditionByName: state => (name: string) => {
      return state.result.value.find(x => x.displayName?.toUpperCase() === name?.toUpperCase())
    },
    getEditionName: state => (id: number) => {
      const edition = state.result.value.find(x => x.id === id)
      return edition && edition.displayName
    }
  },
  mutations: {
    setEdition(state, edition: IEdition) {
      const index = state.result.value.findIndex(x => x.id === edition.id)
      state.result.value.splice(index, 1, edition)
    }
  },
  actions: {
    async getEditions({ commit, dispatch }) {
      const request: ODataRequest = {
        url: 'Editions',
        params: {
          $select: 'id,uniqueId,displayName,description,comments',
          $orderby: 'displayName'
        }
      }
      const result = await dispatch('get', request)
      commit('setResult', result)
    },
    async getEditionById({ commit, dispatch }, id) {
      const request: ODataRequest = {
        url: `Editions(${id})`,
        params: {
          $expand: 'listingEditions($expand=listing($select=id,displayName))'
        }
      }
      const edition = await dispatch('get', request)
      commit('setEdition', edition)
      return edition
    },
    async saveEdition({ dispatch }, edition: IEdition) {
      let result = edition
      if (edition.id) {
        const request: ODataRequest = {
          url: `Editions(${edition.id})`,
          data: edition,
          flatten: true
        }
        await dispatch('put', request)
      } else {
        const request: ODataRequest = {
          url: 'Editions',
          data: edition
        }
        result = await dispatch('post', request)
      }
      await dispatch('getEditions')

      return result
    },
    async deleteEdition({ dispatch }, edition: IEdition) {
      const request: ODataRequest = {
        url: `Editions(${edition.id})`
      }
      await dispatch('delete', request)
      await dispatch('getEditions')
    },
    async reassignEdition({ dispatch }, payload: any) {
      const edition: IEdition = payload.edition
      const replacementEditionId: number = payload.replacementEditionId

      const request: ODataRequest = {
        url: `Editions(${edition.id})/Reassign`,
        data: {
          editionId: replacementEditionId
        }
      }
      await dispatch('post', request)
      await dispatch('deleteEdition', edition)
    }
  }
}))
